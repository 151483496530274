import { useProgress } from "@api/certificate/useProgress"
import { useGDaten } from "@api/g-daten/useGDaten"
import { useProduct } from "@api/product/useProduct"
import { useProject } from "@api/projects/useProject.ts"
import { GradientBackground } from "@components/atoms/gradient"
import { Image } from "@components/atoms/image.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { CertificateGenerationBox } from "@components/molecules/certificate-gen-box"
import { Currency, EnergyCertificateType, ProductId } from "@energuide/shared"
import { useProjectIdParam } from "@hooks/useProjectIdParam"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { useMemo } from "react"

export function EnergyCertificates() {
    const projectId = useProjectIdParam()
    const { data: project } = useProject(projectId)
    const { data: gDaten } = useGDaten(projectId)
    const { name = "---" } = project ?? {}
    const { data: progress } = useProgress(projectId)

    const demandProduct = useProduct(ProductId.DemandCertificate)
    const demandProductPrice = useMemo<Currency | null>(() => {
        if (
            demandProduct.data &&
            typeof demandProduct.data.price === "number" &&
            Number.isInteger(demandProduct.data.price * 100)
        ) {
            try {
                return new Currency("EUR", "Euro", "€", demandProduct.data.price * 100)
            } catch (e) {
                console.error("Error while creating currency object", e)
            }
        }
        return null
    }, [demandProduct])

    const consumptionProduct = useProduct(ProductId.ConsumptionCertificate)
    const consumptionProductPrice = useMemo<Currency | null>(() => {
        if (
            consumptionProduct.data &&
            typeof consumptionProduct.data.price === "number" &&
            Number.isInteger(consumptionProduct.data.price * 100)
        ) {
            try {
                return new Currency("EUR", "Euro", "€", consumptionProduct.data.price * 100)
            } catch (e) {
                console.error("Error while creating currency object", e)
            }
        }
        return null
    }, [consumptionProduct])

    useTitlebar({
        title: name ?? "",
        mode: "back",
        backTo: `/authenticated/chat/${projectId ?? ""}`,
        showContextMenu: true,
        contextMenuProps: {
            showBuildingPass: true,
            showEnergyCertificates: true,
            showProjectSettings: true,
        },
        projectId,
    })

    return (
        <section className="mx-auto grid max-w-screen-md content-start gap-10 pb-4">
            <div className="relative aspect-square object-cover lg:aspect-video">
                <Image
                    src={gDaten?.Gebaeude_Foto ?? ""}
                    alt=""
                    fallback={<i className="ri-home-3-line text-[3rem] text-textLight"></i>}
                    className="h-full w-full"
                />
                <Heading level="h1" className="absolute bottom-3 left-4">
                    {name}
                </Heading>
            </div>

            <section className="grid gap-2 px-6">
                <Heading level="h2" className=" text-text">
                    Energieausweise
                </Heading>
                <Text variant="body2" className="text-textLight">
                    Wähle einen der folgenden Services für dein Gebäude und lass dich von EnerGuide beraten.
                </Text>
            </section>

            {project?.id && consumptionProductPrice && (
                <section className="px-2">
                    <GradientBackground variant="dark" className="gap-4 p-4" radius="big">
                        <CertificateGenerationBox
                            title="Verbrauchsausweis"
                            price={consumptionProductPrice}
                            progress={{
                                value: progress?.consumption_certificate.answered ?? 0,
                                max: progress?.consumption_certificate.total ?? 0,
                            }}
                            projectId={project.id}
                            type={EnergyCertificateType.Consumption}
                            productId={ProductId.ConsumptionCertificate}
                        />
                    </GradientBackground>
                </section>
            )}

            {project?.id && demandProductPrice && (
                <section className="px-2">
                    <GradientBackground variant="dark" className="gap-4 p-4" radius="big">
                        <CertificateGenerationBox
                            title="Bedarfsausweis"
                            price={demandProductPrice}
                            progress={{
                                value: progress?.demand_certificate.answered ?? 0,
                                max: progress?.demand_certificate.total ?? 0,
                            }}
                            projectId={project.id}
                            type={EnergyCertificateType.Demand}
                            productId={ProductId.DemandCertificate}
                        />
                    </GradientBackground>
                </section>
            )}
        </section>
    )
}
