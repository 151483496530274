import { queryClient } from "@api/apiConfig.ts"
import { ErrorFallback } from "@components/atoms/error-fallback.tsx"
import OverlayProvider from "@components/molecules/overlay/overlay-context.tsx"
import * as Sentry from "@sentry/capacitor"
import * as SentryReact from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import React from "react"
import ReactDOM from "react-dom/client"
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import "remixicon/fonts/remixicon.css"
import { App } from "./App.tsx"
import "./index.css"

Sentry.init(
    {
        dsn: import.meta.env.PROD ? import.meta.env.VITE_SENTRY_DSN : null,
        tunnel: import.meta.env.PROD ? "/tunnel" : "http://127.0.0.1:3311/tunnel",
        integrations: [
            new SentryReact.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/api.enerithm\.com\/api/],
            }),
            new SentryReact.Replay({
                maskAllText: false,
                blockAllMedia: false,
                maxReplayDuration: 60000,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    },
    // Forward the init method from @sentry/react
    SentryReact.init
)

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="*"
            element={
                <SentryReact.ErrorBoundary fallback={<ErrorFallback />}>
                    <App />
                </SentryReact.ErrorBoundary>
            }
        />
    )
)

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <SentryReact.ErrorBoundary fallback={<ErrorFallback />}>
            <QueryClientProvider client={queryClient}>
                <OverlayProvider>
                    <RouterProvider router={router} />
                </OverlayProvider>
            </QueryClientProvider>
        </SentryReact.ErrorBoundary>
    </React.StrictMode>
)
