import { z } from "zod"

export const ProjectSchema = z.object({
    id: z.number(),
    name: z.string().nullish(),
    authorizations: z.array(
        z.object({
            user: z.object({
                displayName: z.string().nullish(),
                avatar: z.string().nullish(),
            }),
            role: z.object({
                role: z.string(),
                id: z.number(),
            }),
        })
    ),
    createdAt: z.string(),
    updatedAt: z.string(),
})

export const ProjectsSchema = z.array(ProjectSchema)

export type IProject = z.infer<typeof ProjectSchema>
